import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 통신에 커피와 도넛을 담고, 덤으로 지니뮤직 무료 이용까지",
  "| 데이터 사용량 많은 MZ세대 맞춤형 요금제에 이종산업간 혜택 다양화",
  "| 카톡 통한 간편가입도 큰 장점, 고를 수 있는 혜택으로 소비자 선택권 강화",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/20/RVTlnhxp.jpg" style={{ width: "100%" }} alt="Figure1" />
      </div>
    </div>
    <p>
      <br />
      30일(월) 카카오 계열의 통신사업자인 스테이지파이브(대표 서상원)가 SPC그룹의 토탈 마케팅 솔루션 계열사 섹타나인(대표이사
      이경배)와 손잡고 해피포인트X핀다이렉트샵 제휴 통신상품을 출시했다.
      <br />
      <br />
      해당 요금제는 던킨 커피와 도넛 종류 상관없이 자유롭게 선택하여 무료로 즐길 수 있도록 한 것이 핵심이다. 이와 같은 양사간
      제휴는 통신과 간식비를 따로 지출하던 MZ세대에게 더 유용한 혜택을 제공하는 것이 가능하게 했다는데 의미가 있다.
      <br />
      <br />
      <div className="descImage">
        <div className="img">
          <img src="/images/press/20/PrZflzwz.png" style={{ maxWidth: "330px", margin: "auto" }} alt="Figure2" />
        </div>
        <div className="desc">&lt;해피포인트x완전자유 뭐 이런걸 다 11GB+ (뮤직) 요금제 스펙&gt;</div>
      </div>
      <br />
      이번에 출시한 해피포인트X핀다이렉트샵 제휴 통신상품은 ‘해피포인트x완전자유 뭐 이런걸 다 11GB+ (뮤직) 요금제’로 기본요금
      37,000원에 음성통화와 문자를 무제한으로 사용할 수 있다. 스마트폰으로 콘텐츠를 많이 보는 MZ세대 라이프스타일에 맞춰 스펙도
      강화했다. 데이터 무제한 11GB에 매일 2GB를 추가 제공하고, 이에 더해 매월 150GB를 추가해 증정하는 식이다. 때문에 가입고객이 한
      달에 쓸 수 있는 최대 데이터 량은 총 221GB에 달한다.
      <br />
      <br />
      라이프스타일 맞춤형 요금제는 기본, 추가 혜택까지 꽉꽉 채웠다. 해당 요금제를 구독하면 추가금액 없이 고객의 선택에 따라
      △던킨도너츠 10개 팩 2세트(총 20개) △아메리카노 10잔 중 원하는 혜택을 골라 즐길 수 있다.(3개월간 매달 한 가지 선택 가능)
      또한, 가입 기간 내내 지니뮤직 8,000포인트가 제공되어 원하는 음원도 무료로 즐길 수 있다. 그야말로 MZ세대의
      ‘먹(고)놀(고)폰(하고)’을 책임지는 요금제이다.
      <br />
      <br />
      해당 요금제 제휴를 진행한 스테이지파이브 관계자는 “합리적인 소비를 추구하는 MZ세대에게 보다 유용한 혜택을 제공하기 위해
      SPC그룹의 해피포인트와 함께 제휴 통신요금제를 구성했다”면서 “코로나로 지친 이용자들께 조금이라도 기쁨이 되는 혜택을 제공드릴
      수 있도록 앞으로도 라이프 스타일을 연구한 다양한 통신 상품을 선보일 것”이라고 전했다.
      <br />
      <br />
      합종연횡의 결합 요금제는 앞으로도 계속해서 이어질 전망이다. 스테이지파이브는 지난 7월에도 이용자 라이프 스타일에 꼭 맞는
      혜택을 주고자 신한플러스 요금제를 선보인 바 있다.
      <br />
      <br />
      <br />
    </p>
  </div>
)

const press20 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴협력실"
      writer="커뮤니케이션팀 pr@stagefive.com "
      date="2021년 8월 30일 즉시 배포 가능"
      pageInfo="*총 3매"
      title="“통신비 따로, 간식비 따로? 우린 다같이!” <br /> 핀다이렉트샵, SPC그룹 해피포인트와 손잡고 MZ세대 대상 제휴 통신요금제 출시"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press20
